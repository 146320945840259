import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { toast } from "react-toastify";
import { ChallenegeServices } from "../../../../services/challenge_services";
import CustomToolbar from "../../../../components/toolbar";
import { BASE_PATH_TO_ADD_CHALLENGES, BASE_PATH_TO_CHALLENGE_DETAILS, BASE_PATH_TO_UPDATE_CHALLENGES } from "../../../../base_routes_paths/challenge_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const ChallengesList = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_ChallengesList`;

    const userColumns = [
        {
            field: "id",
            headerName: "Challenege ID",
            width: 230,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_CHALLENGE_DETAILS}/` + params.row.id}>{params.row.id}</a>
                );
            },
        },
        {
            field: "ChallengeThumbURL",
            headerName: "Image",
            width: 230,
            renderCell: (params) => {
                return (
                    <div className={classes.cellWithImg}>
                        <img
                            className={classes.cellImg}
                            src={
                                params.row.ChallengeThumbURL ||
                                "https://www.areno.in/images/logo.png"
                            }
                            alt=""
                        />
                        {params.row.Name}
                    </div>
                );
            },
        },
        {
            field: "CategoryName",
            headerName: "Category name",
            width: 120,
        },
        {
            field: "CategoryID",
            headerName: "Category ID",
            width: 120,
        },
        {
            field: "WorkoutID",
            headerName: "WorkoutID",
            width: 120,
        },
        {
            field: "CorporateID",
            headerName: "CorporateID",
            width: 120,
        },
        {
            field: "GyroMaxAngle",
            headerName: "GyroMaxAngle",
            width: 120,
            renderCell: (params) => {
                return (
                    <div className={classes.cellWithImg}>
                        {params.row.GyroMaxAngle ? params.row.GyroMaxAngle : "N / A"}
                    </div>
                );
            },
        },
        {
            field: "GyroMinAngle",
            headerName: "GyroMinAngle",
            width: 120,
            renderCell: (params) => {
                return (
                    <div className={classes.cellWithImg}>
                        {params.row.GyroMaxAngle ? params.row.GyroMinAngle : "N / A"}
                    </div>
                );
            },
        },
        {
            field: "Gender",
            headerName: "Gender",
            width: 80,
        },
        {
            field: "ClanID",
            headerName: "ClanID",
            width: 80,
        },
        {
            field: "Threshold",
            headerName: "Threshold",
            width: 120,
        },
        {
            field: "StartsTimeUtc",
            headerName: "StartsTimeUtc",
            width: 115,
        },
        {
            field: "StartDate",
            headerName: "StartDate",
            width: 120,
        },
        {
            field: "StartTime",
            headerName: "StartTime",
            width: 120,
        },
        {
            field: "EndsInTimeUtc",
            headerName: "EndsInTimeUtc",
            width: 220,
        },
        {
            field: "EndDate",
            headerName: "EndDate",
            width: 120,
        },
        {
            field: "EndTime",
            headerName: "EndTime",
            width: 120,
        },
        {
            field: "JoinByTimeUtc",
            headerName: "JoinByTimeUtc",
            width: 115,
        },
        {
            field: "JoinByDate",
            headerName: "JoinByDate",
            width: 120,
        },
        {
            field: "JoinByTime",
            headerName: "JoinByTime",
            width: 120,
        },
        {
            field: "TimeDuration",
            headerName: "TimeDuration",
            width: 120,
        },
        {
            field: "Rewards",
            headerName: "Rewards",
            width: 120,
        },
        {
            field: "DeviceType",
            headerName: "DeviceType",
            width: 120,
        },
        {
            field: "SmsVideoUrl",
            headerName: "SmsVideoUrl",
            width: 200,
            renderCell: (params) => {
                const link = params.row.SmsVideoUrl;
                return (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                    </a>
                );
            },
        },
        {
            field: "SetPoint",
            headerName: "Registration points",
            width: 160,
        },
        {
            field: "NoOfAttempts",
            headerName: "Max. Attempts",
            width: 160,
        },
        {
            field: "Participents",
            headerName: "Participants",
            width: 120,
        },
        {
            field: "SubscriberOnly",
            headerName: "SubscriberOnly",
            width: 120,
        },
        {
            field: "IsCumulative",
            headerName: "IsCumulative",
            width: 100,
        },
        {
            field: "IsActive",
            headerName: "IsActive",
            width: 100,
        },
        {
            field: "IsSponsored",
            headerName: "IsSponsored",
            width: 100,
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    let ChallengeData = [];
    const [data, setData] = useState(ChallengeData);
    const navigate = useNavigate();

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await ChallenegeServices.getAllChallenges(token, navigate);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = (id) => {
        ChallenegeServices.deleteChallenge(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_CHALLENGE_DETAILS}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>View</div>
                        </a>
                        <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_UPDATE_CHALLENGES}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>Update</div>
                        </a>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenges
                </Typography>
                <Typography color="text.primary">Challenges List</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_ADD_CHALLENGES);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        ),
                    }}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default ChallengesList;
