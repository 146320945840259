
import { BASE_PATH_TO_CLAN, BASE_PATH_TO_CLAN_CHATS_LIST, BASE_PATH_TO_CLANS_LIST } from "../base_routes_paths/clans_paths";
import DashboardPageLayout from "../layouts/dashboard_layout";
import AllClanChatsList from "../pages/clan/clan_chats/view_all_chats";
import AllClansList from "../pages/clan/clans/view_all_clans";
import { ProtectedRoutes } from "./protected_route";

const ClanRoutes = {
    path: BASE_PATH_TO_CLAN,
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_CLANS_LIST,
            element: <AllClansList />,
        },
        {
            path: `${BASE_PATH_TO_CLAN_CHATS_LIST}/:ID`,
            element: <AllClanChatsList />,
        }
    ],
};

export default ClanRoutes;
