import classes from "./Chart.module.css";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Bar,
} from "recharts";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AUTH_CONTEXT from "../../store";
import { StatusCode } from "../../utils";
import ARENO_API_ENDPOINT from "../../api";
import { WorkoutServices } from "../../services/challenge_services";
import { CircularProgress } from "@mui/material";

const UserGroupActivityChart = ({ aspect, title }) => {
    const { token } = AUTH_CONTEXT();
    const [workoutData, setWorkoutData] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const [TimeDuration, setTimeDuration] = useState("week");
    const navigate = useNavigate();

    const FetchApi = async () => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetAllUserActivityInGroup`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        TimeDuration: TimeDuration
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            setIsDataFetched(true);
            return response.data.GraphData;
        } catch (e) {
            setIsDataFetched(false);
            console.log(e.message);
            alert(e.message);
        }
    };

    const FetchApiWorkoutIDs = async () => {
        try {
            let response = await WorkoutServices.getAllWorkout(token, navigate);

            let result = response.data;

            return result;
        } catch (e) {
            setIsDataFetched(false);
        }
    };

    useEffect(() => {
        async function FetchWorkoutData() {
            try {
                if (workoutData.length === 0) {
                    const fetchedWorkoutData = await FetchApiWorkoutIDs();
                    setWorkoutData(fetchedWorkoutData);
                }
            } catch (err) {
                console.log(err.message);
            }
        }
        FetchWorkoutData();
    }, []);

    useEffect(() => {
        async function FetchData() {
            try {
                if (!isDataFetched) {
                    let graphData = await FetchApi();

                    const updatedData = graphData.map((item) => {
                        const workout = workoutData.find(w => w.WorkoutID === item.WorkoutID);
                        if (workout) {
                            return { ...item, WorkoutName: workout.WorkoutName };
                        }
                        return item;
                    });

                    setData(updatedData);
                    setIsDataFetched(true);
                }
            } catch (err) {
                console.log(err.message);
            }
        }
        if (workoutData.length > 0) {
            FetchData();
        }
    }, [TimeDuration, workoutData]);

    const TimeDurationHandler = (event) => {
        event.preventDefault();
        setTimeDuration(event.target.value);
        setIsDataFetched(false);
    };

    return (
        <div className={classes.chart}>
            <div className={classes.title}>
                <div className={classes.subtitle}>{title}</div>
                <div>
                    <div className={classes.selectbox}>
                        <label htmlFor="CategoryName" style={{ fontSize: "20px" }}>Duration: </label>
                        <select
                            name="CategoryName"
                            id="CategoryName"
                            value={TimeDuration}
                            onChange={(e) => TimeDurationHandler(e)}
                        >
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="halfyear">6 months</option>
                            <option value="year">1 Year</option>
                        </select>
                    </div>
                </div>
            </div>

            {
                isDataFetched ? (
                    <div>
                        <ResponsiveContainer width="100%" aspect={aspect}>
                            <BarChart
                                className={classes.AreaChart}
                                width={650}
                                height={250}
                                data={data}
                                margin={{ top: 1, right: 20, left: -10, bottom: 0 }}
                            >
                                <defs>
                                    <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="black" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="WorkoutName" stroke="black" />
                                <YAxis dataKey="Count" stroke="black"></YAxis>
                                <CartesianGrid
                                    strokeDasharray="3 3"
                                    className={classes.chartGrid}
                                />
                                <Tooltip />
                                <Bar dataKey="Count" barSize={15} fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                ) : (
                    <div className={classes.loaderContainer}>
                        <CircularProgress />
                    </div>
                )
            }
            <div className={classes.BottomHeading}>(WorkoutName)</div>
        </div>
    );
};

export default UserGroupActivityChart;
