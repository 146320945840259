import { toast } from "react-toastify";
import ARENO_API_ENDPOINT from "../../api";
import { StatusCode } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ClansServices = {
    getAllClans: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/clan/AdminGetAllClans`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    }
};

const ClanChatsServices = {
    getAllClanChats: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/clan/AdminGetAllClanChats`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    }
};


export {
    ClansServices,
    ClanChatsServices
};
