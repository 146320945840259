import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Redeem.module.css";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { AFAServices } from "../../../../services/redeem_services";
import { BASE_PATH_TO_AFA_LIST } from "../../../../base_routes_paths/redeem_paths";

const AddAFA = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        CommunityGoalSectionConfig: {
            IOS: true,
            Android: true
        },
        GameSectionConfig: {
            IOS: true,
            Android: true
        },
        MeditationSectionConfig: {
            IOS: true,
            Android: true
        },
        YogaSectionConfig: {
            IOS: true,
            Android: true
        }
    });
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (section, platform, value) => {
        setData((prevData) => ({
            ...prevData,
            [section]: {
                ...prevData[section],
                [platform]: value === "true" ? true : false
            }
        }));
    };

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const FetchApi = async () => {
            try {
                let response = await AFAServices.addAFA(token, navigate, data);

                if (response.status === 207) {
                    return toast.error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
                }
                setIsLoading(false);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate(BASE_PATH_TO_AFA_LIST);
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Redeem
                </Typography>
                <Typography color="text.primary">Add App Feature Available</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>App Feature Available</div>
                <form onSubmit={submitHandler}>
                    {/* Community Goal Section */}
                    <div className={classes.subfields}>
                        <h3>Community</h3>
                        <div className={classes.selectbox}>
                            <label htmlFor="IsCommunityGoalSectionActiveIOS">iOS: </label>
                            <select
                                name="IsCommunityGoalSectionActiveIOS"
                                value={data.CommunityGoalSectionConfig.IOS}
                                onChange={(e) => handleInputChange('CommunityGoalSectionConfig', 'IOS', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                        <div className={classes.selectbox}>
                            <label htmlFor="IsCommunityGoalSectionActiveAndroid">Android: </label>
                            <select
                                name="IsCommunityGoalSectionActiveAndroid"
                                value={data.CommunityGoalSectionConfig.Android}
                                onChange={(e) => handleInputChange('CommunityGoalSectionConfig', 'Android', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                    </div>

                    {/* Game Section */}
                    <div className={classes.subfields}>
                        <h3>Game</h3>
                        <div className={classes.selectbox}>
                            <label htmlFor="IsGameSectionActiveIOS">iOS: </label>
                            <select
                                name="IsGameSectionActiveIOS"
                                value={data.GameSectionConfig.IOS}
                                onChange={(e) => handleInputChange('GameSectionConfig', 'IOS', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                        <div className={classes.selectbox}>
                            <label htmlFor="IsGameSectionActiveAndroid">Android: </label>
                            <select
                                name="IsGameSectionActiveAndroid"
                                value={data.GameSectionConfig.Android}
                                onChange={(e) => handleInputChange('GameSectionConfig', 'Android', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                    </div>

                    {/* Meditation Section */}
                    <div className={classes.subfields}>
                        <h3>Meditation</h3>
                        <div className={classes.selectbox}>
                            <label htmlFor="IsMeditationSectionActiveIOS">iOS: </label>
                            <select
                                name="IsMeditationSectionActiveIOS"
                                value={data.MeditationSectionConfig.IOS}
                                onChange={(e) => handleInputChange('MeditationSectionConfig', 'IOS', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                        <div className={classes.selectbox}>
                            <label htmlFor="IsMeditationSectionActiveAndroid">Android: </label>
                            <select
                                name="IsMeditationSectionActiveAndroid"
                                value={data.MeditationSectionConfig.Android}
                                onChange={(e) => handleInputChange('MeditationSectionConfig', 'Android', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                    </div>

                    {/* Yoga Section */}
                    <div className={classes.subfields}>
                        <h3>Yoga</h3>
                        <div className={classes.selectbox}>
                            <label htmlFor="IsYogaSectionActiveIOS">iOS: </label>
                            <select
                                name="IsYogaSectionActiveIOS"
                                value={data.YogaSectionConfig.IOS}
                                onChange={(e) => handleInputChange('YogaSectionConfig', 'IOS', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                        <div className={classes.selectbox}>
                            <label htmlFor="IsYogaSectionActiveAndroid">Android: </label>
                            <select
                                name="IsYogaSectionActiveAndroid"
                                value={data.YogaSectionConfig.Android}
                                onChange={(e) => handleInputChange('YogaSectionConfig', 'Android', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card >
        </div >
    );
};

export default AddAFA;
