import { useState, useEffect } from "react";
import {
    DataGridPremium,
} from "@mui/x-data-grid-premium";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Clan.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_USER_DETAILS } from "../../../../base_routes_paths/data_paths";
import CustomToolbar from "../../../../components/toolbar";
import { ClanChatsServices } from "../../../../services/clan_services";

const AllClanChatsList = () => {
    const userColumns = [
        {
            field: "UserID",
            headerName: "User ID",
            width: 230,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.UserID}>{params.row.UserID}</a>
                );
            },
        },
        {
            field: "UserName",
            headerName: "UserName",
            width: 150,
        },
        {
            field: "ProfileImageURL",
            headerName: "ProfileImageURL",
            width: 150,
            renderCell: (params) => {
                return (
                    <div className={classes.cellWithImg}>
                        <img
                            className={classes.cellImg}
                            src={params.row.ProfileImageURL || "https://areno.in/img/group-1@2x.svg"}
                            alt=""
                        />
                    </div>
                );
            },
        },
        {
            field: "IsSubscribed",
            headerName: "IsSubscribed",
            width: 120,
        },
        {
            field: "Message",
            headerName: "Message",
            width: 300,
        }
    ];

    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_ClanChatsList`;
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const params = useParams();

    const FetchApi = async () => {
        try {
            let response = await ClanChatsServices.getAllClanChats(token, navigate, params.ID);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            let result = response.data;
            let input = result.map(({ _id: id, ...rest }) => ({
                id: id || rest.UserID,  // Use _id or fallback to UserID if _id is missing
                ...rest,
            }));
            setData(input);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi();
        }
    }, []);

    return (
        <div>
            {isDataFetched && (
                <div className={classes.datatable}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Clan
                        </Typography>
                        <Typography color="text.primary">Clan Chats List</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        rows={data}
                        columns={userColumns}
                        slots={{
                            toolbar: () => (
                                <CustomToolbar
                                    EXPORT_FILENAME={EXPORT_FILENAME}
                                    // onClickCreate={() => {
                                    //     navigate(BASE_PATH_TO_ADD_POINT_PURCHASE_VOUCHERS);
                                    // }}
                                    displayCreate={"none"}
                                    onClickUpdate={() => { }}
                                    displayUpdate={"none"}
                                />
                            ),
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                        }}
                        disableRowSelectionOnClick
                        checkboxSelectionVisibleOnly
                        checkboxSelection
                    />
                </div>
            )}
            {!isDataFetched && <Loader />}
        </div>
    );
};

export default AllClanChatsList;
