import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { Link } from "react-router-dom";
import classes from "./SingleUser.module.css";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Box, IconButton, Popover } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { toast } from "react-toastify";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import TvIcon from '@mui/icons-material/Tv';
import Loader from "../../../../components/Loader/Loader";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { StatusCode } from "../../../../utils";
import ARENO_API_ENDPOINT from "../../../../api";
import AUTH_CONTEXT from "../../../../store";
import { UserServices } from "../../../../services/auth_services";
import { BASE_PATH_TO_UPDATE_FEEDBACKS, BASE_PATH_TO_USER_All_ACTIVITIES, BASE_PATH_TO_USER_CHALLENGE_ATTEMPTS, BASE_PATH_TO_USER_CHALLENGE_SNAPSHOTS, BASE_PATH_TO_USER_RECOMMENDATIONS, BASE_PATH_TO_USER_SOLO_CHALLENGE, BASE_PATH_TO_USER_SUBSCRIPTION } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_ADD_SPECIAL_REFERRAL } from "../../../../base_routes_paths/auth_paths";
import { BASE_PATH_TO_ADD_USER_POINTS } from "../../../../base_routes_paths/points_paths";

const UserDetails = () => {
    const { token } = AUTH_CONTEXT()

    let UserRecord = [];
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const [dataArray, setData] = useState(UserRecord);
    const params = useParams();
    const navigate = useNavigate();

    const FetchResponseGetUserDetailsByUserID = async () => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/auth/AdminGetUserDetailsFromID`, {
                method: "POST",
                body: JSON.stringify({
                    _ids: params.UserID,
                }),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            if (response.data.UserRecords.length === 0) {
                throw new Error("No User Found!");
            }
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoaderLoading(false);
            return response.data.UserRecords;
        } catch (e) {
            setIsDataFetched(false);
            console.log(e.message);
            alert(e.message);
        }
    };

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    useEffect(() => {
        if (!isDataFetched) {
            setIsLoaderLoading(true);
            FetchResponseGetUserDetailsByUserID()
                .then((result) => {
                    setData(result);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    const handleDelete = (id, flag) => {
        UserServices.blockUser(id, flag, token);
    };

    return (
        <div>
            {isLoaderLoading === true ? (
                <Loader />
            ) : (
                <>
                    {dataArray.length > 0 && (
                        <>
                            <h2 style={{ margin: "40px", color: "black" }}>User Profile / {params.UserID}</h2>
                            <div
                                style={{ margin: "0px 40px 40px 40px" }}
                                className={classes.single}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderRadius: "1rem",
                                        width: "100%",
                                        height: "24rem",
                                        backgroundColor: "#4f005f",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        position: "relative",
                                        padding: "20px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            justifyContent: "left",
                                            color: "white",
                                            fontSize: "1.5rem",
                                            paddingLeft: "1rem",
                                        }}
                                    >
                                        <div style={{ lineHeight: "2.2rem" }}>
                                            <div>UserID : {dataArray[0]._id}</div>
                                            <div>Name : {dataArray[0].Name}</div>
                                            <div>Age : {dataArray[0].Age}</div>
                                            <div>Mobile Number : {dataArray[0].Mobile_Number}</div>
                                            <div>Gender : {dataArray[0].Gender}</div>
                                            <div>Location : {dataArray[0].Location}</div>
                                            <div>City : {dataArray[0].City}</div>
                                            <div>YearOfBirth : {dataArray[0].YearOfBirth}</div>
                                            <div>CountryCode : {dataArray[0].CountryCode}</div>
                                            <div>Email : {dataArray[0].Email !== "" ? dataArray[0].Email : "N / A "}</div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src={
                                                dataArray[0].ProfileImage ||
                                                "https://www.areno.in/images/logo.png"
                                            }
                                            alt={dataArray[0].Name || ""}
                                            className={classes.itemImg}
                                            style={{
                                                width: "18rem",
                                                height: "18rem",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                border: "2px solid black",
                                                borderRadius: "20rem",
                                            }}
                                        />
                                    </div>
                                    <MenuIcon
                                        style={{
                                            position: "absolute",
                                            top: "1rem",
                                            right: "1rem",
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                        fontSize="large"
                                        onClick={handleOpen}
                                    />
                                </div>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    sx={{ mt: 2, width: "300px" }}
                                >
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                        }}
                                        onClick={() => {
                                            handleDelete(dataArray[0]._id, !dataArray[0].IsActive);
                                        }}
                                    >
                                        Block User
                                        <IconButton>
                                            <NoAccountsIcon className={classes["icon-color"]} />
                                        </IconButton>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                        }}
                                    >
                                        <Link
                                            to={`${BASE_PATH_TO_USER_CHALLENGE_SNAPSHOTS}/` + dataArray[0]._id}
                                            className={classes["button-link"]}
                                        >
                                            User Settings
                                            <IconButton>
                                                <ManageAccountsIcon className={classes["icon-color"]} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                        }}
                                    >
                                        <Link
                                            to={BASE_PATH_TO_ADD_USER_POINTS}
                                            onClick={() => localStorage.setItem("userID", params.UserID)}
                                            className={classes["button-link"]}
                                        >
                                            Add Points
                                            <IconButton>
                                                <ControlPointIcon className={classes["icon-color"]} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                            width: "230px",
                                        }}
                                    >
                                        <Link
                                            to={BASE_PATH_TO_ADD_SPECIAL_REFERRAL}
                                            onClick={() => {
                                                localStorage.setItem(
                                                    "ReferralCode",
                                                    dataArray[0].ReferralCode
                                                )
                                            }}
                                            className={classes["button-link"]}
                                        >
                                            Add Special Referral
                                            <IconButton>
                                                <ControlPointIcon className={classes["icon-color"]} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                            width: "230px"
                                        }}
                                    >
                                        <Link
                                            to={`${BASE_PATH_TO_USER_CHALLENGE_ATTEMPTS}/` + params.UserID}
                                            className={classes["button-link"]}
                                        >
                                            Challenge Attempts
                                            <IconButton>
                                                <EmojiEventsIcon className={classes["icon-color"]} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                            width: "238px"
                                        }}
                                    >
                                        <Link
                                            to={`${BASE_PATH_TO_USER_SOLO_CHALLENGE}/` + params.UserID}
                                            className={classes["button-link"]}
                                        >
                                            Solo Challenge Details
                                            <IconButton>
                                                <EmojiEventsIcon className={classes["icon-color"]} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                            width: "230px"
                                        }}
                                    >
                                        <Link
                                            to={`${BASE_PATH_TO_USER_SUBSCRIPTION}/` + params.UserID}
                                            className={classes["button-link"]}
                                        >
                                            Subscriptions
                                            <IconButton>
                                                <SubscriptionsIcon className={classes["icon-color"]} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                            width: "230px"
                                        }}
                                    >
                                        <Link
                                            to={`${BASE_PATH_TO_USER_RECOMMENDATIONS}/` + params.UserID}
                                            className={classes["button-link"]}
                                        >
                                            Onboarding Screen Recommendations
                                            <IconButton>
                                                <TvIcon className={classes["icon-color"]} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                            width: "230px"
                                        }}
                                    >
                                        <Link
                                            to={`${BASE_PATH_TO_UPDATE_FEEDBACKS}/` + params.UserID}
                                            className={classes["button-link"]}
                                        >
                                            User Feedbacks
                                            <IconButton>
                                                <QuestionAnswerIcon className={classes["icon-color"]} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 2,
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            "&&:hover ": { bgcolor: "#f0f0f0" },
                                            width: "230px"
                                        }}
                                    >
                                        <Link
                                            to={`${BASE_PATH_TO_USER_All_ACTIVITIES}/` + params.UserID}
                                            className={classes["button-link"]}
                                        >
                                            User Activities
                                            <IconButton>
                                                <LocalActivityIcon className={classes["icon-color"]} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                </Popover>
                                <div style={{ padding: "20px" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            border: "1px solid #000",
                                            margin: "20px 0",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: "1",
                                                textAlign: "center",
                                                padding: "10px",
                                            }}
                                        >
                                            <div
                                                style={{ display: "flex", justifyContent: "center" }}
                                            >
                                                <div style={{ flex: "1", textAlign: "center" }}>
                                                    <div className={classes.itemKey}>
                                                        Height In Feet :
                                                    </div>{" "}
                                                    <span>{dataArray[0].HeightFt}ft</span>
                                                </div>

                                                <div style={{ flex: "1", textAlign: "center" }}>
                                                    <div className={classes.itemKey}>
                                                        Height In Inch :
                                                    </div>{" "}
                                                    <span>{dataArray[0].HeightIn}in</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                borderLeft: ".1px solid #000",
                                                height: "50px",
                                                margin: "auto",
                                            }}
                                        />

                                        <div style={{ flex: "1", textAlign: "center" }}>
                                            <div className={classes.itemKey}>Weight In kg :</div>{" "}
                                            <p>{dataArray[0].Wt_kg}kg</p>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "20px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div
                                                className={classes.itemKey}
                                                style={{ marginLeft: "0" }}
                                            >
                                                Role :
                                            </div>
                                            <p>{dataArray[0].Role}</p>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>ReferralCode :</div>
                                            <p>{dataArray[0].ReferralCode}</p>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>ReferedBy :</div>
                                            <p>
                                                {dataArray[0].ReferedBy
                                                    ? dataArray[0].ReferedBy
                                                    : "N / A"}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>CorporateID :</div>
                                            <p>{dataArray[0].CorporateID}</p>
                                        </div>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Activity Status :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].ActivityStatus || "N / A"}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>IsEmailVerified :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].IsEmailVerified && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {!dataArray[0].IsEmailVerified && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Get To Know From :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].GetToKnowFrom || "N / A"}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Time Off Set :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].TimeOffSet || "N / A"}
                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "20px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>AppVersion :</div>
                                            <p>
                                                {dataArray[0].AppVersion
                                                    ? dataArray[0].AppVersion
                                                    : "N / A"}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>DeviceID :</div>
                                            <p>
                                                {dataArray[0].DeviceID
                                                    ? dataArray[0].DeviceID
                                                    : "N / A"}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>DeviceType :</div>
                                            <p>
                                                {dataArray[0].DeviceType
                                                    ? dataArray[0].DeviceType
                                                    : "N / A"}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <span className={classes.itemKey}>OSVersion :</span>
                                            <p>
                                                {dataArray[0].OSVersion
                                                    ? dataArray[0].OSVersion
                                                    : "N / A"}
                                            </p>
                                        </div>
                                    </div>


                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Build Number :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].BuildNumber || "N / A"}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Country :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].Country || "N / A"}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>ShowOnboardingScreen :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].ShowOnboardingScreen && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {!dataArray[0].ShowOnboardingScreen && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>IsOnboardingComplete :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].IsOnboardingComplete && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {!dataArray[0].IsOnboardingComplete && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>IsQuestionnaireComplete :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].IsQuestionnaireComplete && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {!dataArray[0].IsQuestionnaireComplete && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "20px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>TeamID :</div>
                                            <p>
                                                {dataArray[0].TeamID
                                                    ? dataArray[0].TeamID
                                                    : "N / A"}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>SchoolGrade :</div>
                                            <p>
                                                {dataArray[0].SchoolGrade
                                                    ? dataArray[0].SchoolGrade
                                                    : "N / A"}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>SchoolSection :</div>
                                            <p>
                                                {dataArray[0].SchoolSection
                                                    ? dataArray[0].SchoolSection
                                                    : "N / A"}
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "20px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>SubscriptionStartsOn :</div>
                                            {dataArray[0].SubscriptionStartsOn
                                                ? dataArray[0].SubscriptionStartsOn
                                                : "N / A"}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>SubscriptionEndsOn :</div>
                                            <p>
                                                {dataArray[0].SubscriptionEndsOn
                                                    ? dataArray[0].SubscriptionEndsOn
                                                    : "N / A"}
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "20px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>IsRatedApp :</div>
                                            {dataArray[0].IsRatedApp && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {!dataArray[0].IsRatedApp && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>IsSubscribed :</div>
                                            {dataArray[0].IsSubscribed && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {!dataArray[0].IsSubscribed && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>IsSubscribedByCorporate :</div>
                                            {dataArray[0].IsSubscribedByCorporate && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {!dataArray[0].IsSubscribedByCorporate && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>IsActive :</div>
                                            {dataArray[0].IsActive && (
                                                <p style={{ color: "green" }}>Active</p>
                                            )}
                                            {!dataArray[0].IsActive && (
                                                <p style={{ color: "red" }}>Blocked</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Rating Given :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].RatingGiven || "N / A"}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Feedback :</span>
                                        <span className={classes.itemValue}>
                                            {dataArray[0].Feedback || "N / A"}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>
                                            Referred User List :{" "}
                                            {dataArray[0].ReferredUserList.length}
                                        </span>
                                        {dataArray[0].ReferredUserList.length > 0 &&
                                            dataArray[0].ReferredUserList.map((item, index) => {
                                                return (
                                                    <a
                                                        href={
                                                            "/GetUserDetails/" +
                                                            dataArray[0].ReferredUserList[index].UserID
                                                        }
                                                        style={{ textDecoration: "none" }}
                                                    >
                                                        <span className={classes.UserList}>
                                                            <h4>{index + 1}.</h4>
                                                            {dataArray[0].ReferredUserList[index].UserID ||
                                                                ""}
                                                        </span>
                                                    </a>
                                                );
                                            })}
                                    </div>
                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>
                                            ClanIDs :{" "}
                                            {dataArray[0].ClanIDs.length}
                                        </span>
                                        {dataArray[0].ClanIDs.length > 0 &&
                                            dataArray[0].ClanIDs.map((item, index) => {
                                                return (
                                                    <span
                                                        style={{ textDecoration: "none" }}
                                                    >
                                                        <span className={classes.UserList}>
                                                            <h4>{index + 1}.</h4>
                                                            {item ||
                                                                ""}
                                                        </span>
                                                    </span>
                                                );
                                            })}
                                    </div>
                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>
                                            OTP_attempted : {dataArray[0].OTP_attempted.length}
                                        </span>
                                        {dataArray[0].OTP_attempted.length > 0 &&
                                            dataArray[0].OTP_attempted.map((item, index) => {
                                                return (
                                                    <span className={classes.UserList}>
                                                        <h4>{index + 1}.</h4>
                                                        {dataArray[0].OTP_attempted[index].UserID || ""}
                                                    </span>
                                                );
                                            })}
                                    </div>
                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>
                                            User Subscription IDs :{" "}
                                            {dataArray[0].UserSubscriptionIDs.length}
                                        </span>
                                        {dataArray[0].UserSubscriptionIDs.length > 0 &&
                                            dataArray[0].UserSubscriptionIDs.map((item, index) => {
                                                return (
                                                    <span className={classes.UserList}>
                                                        <h4>{index + 1}.</h4>
                                                        {dataArray[0].UserSubscriptionIDs[index].UserSubscriptionID ||
                                                            ""}
                                                    </span>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default UserDetails;