import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { WorkoutServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_WORKOUT_LIST } from "../../../../base_routes_paths/challenge_paths";

const CreateWorkoutIdConfig = () => {
    const navigate = useNavigate();
    const [workoutID, setWorkoutID] = useState("");
    const [workoutUnit, setWorkoutUnit] = useState("");
    const [sortID, setSortID] = useState(null);
    const [workoutName, setWorkoutName] = useState("");
    const [WorkoutImage, setWorkoutImage] = useState("");
    const [calories, setCalories] = useState("");
    const [isRepBased, setIsRepBased] = useState(true);
    const [DeviceType, setDeviceType] = useState("All");
    const [isNew, setIsNew] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [link, setLink] = useState([{ key: "", value: "" }]);
    const [inputUrlImg, setInputUrlImg] = useState([{ url: "" }]);
    const [inputUrlVideo, setInputUrlVideo] = useState([{ url: "" }]);
    const [GyroMinAngle, setGyroMinAngle] = useState("");
    const [Description, setDescription] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [GyroMaxAngle, setGyroMaxAngle] = useState("");
    const [IsAvailableForClan, setIsAvailableForClan] = useState(false);

    const { token } = AUTH_CONTEXT();

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            WorkoutID: +workoutID,
            WorkoutName: workoutName,
            Calories: calories,
            IsRepBased: isRepBased,
            WorkoutImage: WorkoutImage,
            Links: link,
            WorkoutUnit: workoutUnit,
            SortID: sortID,
            IsActive: isActive,
            IsNew: isNew,
            MediaImg: inputUrlImg,
            MediaVideo: inputUrlVideo,
            GyroMinAngle: +GyroMinAngle,
            GyroMaxAngle: +GyroMaxAngle,
            Description: Description,
            DeviceType: DeviceType,
            IsAvailableForClan: IsAvailableForClan
        }

        const FetchResponse = async () => {
            try {
                let response = await WorkoutServices.addWorkout(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_WORKOUT_LIST);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    const FetchApi = async () => {
        try {
            let response = await WorkoutServices.getAllWorkout(token, navigate);

            const SortID = response.data.map(obj => obj.SortID);
            const highestSortID = Math.max(...SortID);

            setSortID(highestSortID + 1);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        FetchApi();

    }, []);

    const addFieldsLink = (event) => {
        event.preventDefault();
        let newfield = { key: "", value: "" };
        setLink([...link, newfield]);
    };

    const handleFormChangeLink = (index, event) => {
        let data = [...link];
        data[index][event.target.name] = event.target.value;
        setLink(data);
    };

    const removeFieldsLink = (index) => {
        let data = [...link];
        data.splice(index, 1);
        setLink(data);
    };

    const handleFormChangeImg = (index, event) => {
        let data = [...inputUrlImg];
        data[index][event.target.name] = event.target.value;
        setInputUrlImg(data);
    };

    const addFieldsImg = (event) => {
        event.preventDefault();
        let newfield = { url: "" };
        setInputUrlImg([...inputUrlImg, newfield]);
    };

    const removeFieldsImg = (index) => {
        let data = [...inputUrlImg];
        data.splice(index, 1);
        setInputUrlImg(data);
    };
    const handleFormChangeVideo = (index, event) => {
        let data = [...inputUrlVideo];
        data[index][event.target.name] = event.target.value;
        setInputUrlVideo(data);
    };

    const addFieldsVideo = (event) => {
        event.preventDefault();
        let newfield = { url: "" };
        setInputUrlVideo([...inputUrlVideo, newfield]);
    };

    const removeFieldsVideo = (index) => {
        let data = [...inputUrlVideo];
        data.splice(index, 1);
        setInputUrlVideo(data);
    };

    const handleDescriptionChange = (e, locale) => {
        setDescription(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenege
                </Typography>
                <Typography color="text.primary">Add Workout</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add New Workout</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.selectbox}>
                        <label htmlFor="WorkoutID">WorkoutID: </label>
                        <input
                            type="number"
                            id="WorkoutID"
                            required
                            value={workoutID}
                            onChange={(e) => setWorkoutID(e.target.value)}
                            placeholder="Enter unique ID eg. 1, 2, etc."
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="WorkoutName">WorkoutName</label>
                        <input
                            type="text"
                            id="WorkoutName"
                            required
                            value={workoutName}
                            onChange={(e) => setWorkoutName(e.target.value)}
                            placeholder="Enter Name for workout"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="WorkoutImage">Workout Image : </label>
                        <input
                            required
                            id="WorkoutImage"
                            type="url"
                            placeholder="Enter WorkoutImage URL"
                            value={WorkoutImage}
                            onChange={(e) => setWorkoutImage(e.target.value)}
                        />
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="WorkoutUnit">WorkoutUnit</label>
                        <input
                            type="text"
                            id="WorkoutUnit"
                            required
                            value={workoutUnit}
                            onChange={(e) => setWorkoutUnit(e.target.value)}
                            placeholder="Enter WorkoutUnit"
                        />
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="Calories">Calories Burnt</label>
                        <input
                            type="number"
                            id="Calories"
                            required
                            value={calories}
                            onChange={(e) => setCalories(e.target.value)}
                            placeholder="Enter Calories burnt out during workout (per reps or min)"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="GyroMinAngle">GyroMinAngle : </label>
                        <input
                            id="GyroMinAngle"
                            type="number"
                            placeholder="Enter the minimum angle for Gyro"
                            value={GyroMinAngle}
                            onChange={(e) => setGyroMinAngle(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="GyroMaxAngle">GyroMaxAngle : </label>
                        <input
                            id="GyroMaxAngle"
                            type="number"
                            placeholder="Enter the maximum angle for Gyro"
                            value={GyroMaxAngle}
                            onChange={(e) => setGyroMaxAngle(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="SortID">SortID</label>
                        <input
                            type="number"
                            id="SortID"
                            required
                            value={sortID}
                            onChange={(e) => setSortID(e.target.value)}
                            placeholder="Enter unique ID eg. 1, 2, etc."
                        />
                    </div>

                    <div className={classes.subfields}>
                        <h3>Media Image</h3>
                        {inputUrlImg.map((input, index) => {
                            return (
                                <div key={index} className={classes.subfieldsControl}>
                                    <div className={classes.control}>
                                        <label htmlFor="url">Url</label>
                                        <input
                                            type="text"
                                            name="url"
                                            placeholder="Enter Url for Image of Media"
                                            value={input.url}
                                            onChange={(event) => handleFormChangeImg(index, event)}
                                        />
                                    </div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            removeFieldsImg(index);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            );
                        })}
                        <button onClick={addFieldsImg}>Add More...</button>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Media Video</h3>
                        {inputUrlVideo.map((input, index) => {
                            return (
                                <div key={index} className={classes.subfieldsControl}>
                                    <div className={classes.control}>
                                        <label htmlFor="url">Url</label>
                                        <input
                                            type="text"
                                            name="url"
                                            placeholder="Enter Url for video of media"
                                            value={input.url}
                                            onChange={(event) => handleFormChangeVideo(index, event)}
                                        />
                                    </div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            removeFieldsVideo(index);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            );
                        })}
                        <button onClick={addFieldsVideo}>Add More...</button>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Links</h3>
                        {link.map((input, index) => {
                            return (
                                <div key={index} className={classes.subfieldsControl}>
                                    <div className={classes.control}>
                                        <label htmlFor="key">Key</label>
                                        <input
                                            type="text"
                                            name="key"
                                            placeholder="Enter key"
                                            value={input.key}
                                            onChange={(event) => handleFormChangeLink(index, event)}
                                        />
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor="value">Value</label>
                                        <input
                                            type="text"
                                            name="value"
                                            placeholder="Enter value"
                                            value={input.value}
                                            onChange={(event) => handleFormChangeLink(index, event)}
                                        />
                                    </div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            removeFieldsLink(index);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            );
                        })}
                        <button onClick={addFieldsLink}>Add More...</button>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsRepBased">IsRepBased : </label>
                        <select
                            name="IsRepBased"
                            id="IsRepBased"
                            value={isRepBased}
                            onChange={(e) => setIsRepBased(e.target.value)}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsNew">IsActive : </label>
                        <select
                            name="IsNew"
                            id="IsNew"
                            value={isActive}
                            onChange={(e) => setIsActive(e.target.value)}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsNew">IsNew : </label>
                        <select
                            name="IsNew"
                            id="IsNew"
                            value={isNew}
                            onChange={(e) => setIsNew(e.target.value)}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsAvailableForClan">IsAvailableForClan : </label>
                        <select
                            name="IsAvailableForClan"
                            id="IsAvailableForClan"
                            value={IsAvailableForClan}
                            onChange={(e) => setIsAvailableForClan(e.target.value)}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>

                    <div className={classes.selectbox} >
                        <label htmlFor="DeviceType">DeviceType : </label>
                        <select
                            name="DeviceType"
                            id="DeviceType"
                            value={DeviceType}
                            onChange={(e) => setDeviceType(e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="IOS">IOS</option>
                            <option value="Android">Android</option>
                        </select>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Descriptions</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Description (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Description["en-US"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-US")}
                                    placeholder="Enter Description for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Description (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Description["en-IN"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-IN")}
                                    placeholder="Enter Description for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Description (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Description["en-CA"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-CA")}
                                    placeholder="Enter Description for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default CreateWorkoutIdConfig;
