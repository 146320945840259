import DashboardPageLayout from "../layouts/dashboard_layout";
import { ProtectedRoutes } from "./protected_route";
import AnnouncementsList from "../pages/sundries/announcements/announcement_list";
import AddAnnouncement from "../pages/sundries/announcements/add_announcement";
import UpdateAnnouncement from "../pages/sundries/announcements/update_announcement";
import AddReminderImage from "../pages/sundries/reminder_images/add_reminder_images";
import ReminderImages from "../pages/sundries/reminder_images/reminder_images_list";
import UpdateReminderImage from "../pages/sundries/reminder_images/update_reminder_images";
import {
    BASE_PATH_TO_ADD_ANNOUNCEMENTS,
    BASE_PATH_TO_ADD_REMINDER_IMAGES,
    BASE_PATH_TO_ANNOUNCEMENTS_LIST,
    BASE_PATH_TO_REMINDER_IMAGES_LIST,
    BASE_PATH_TO_SUNDRIES,
    BASE_PATH_TO_UPDATE_ANNOUNCEMENTS,
    BASE_PATH_TO_UPDATE_REMINDER_IMAGES,
} from "../base_routes_paths/sundries_paths";

const SundriesRoutes = {
    path: BASE_PATH_TO_SUNDRIES,
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_ADD_ANNOUNCEMENTS,
            element: <AddAnnouncement />,
        },
        {
            path: BASE_PATH_TO_ANNOUNCEMENTS_LIST,
            element: <AnnouncementsList />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_ANNOUNCEMENTS}/:ID`,
            element: <UpdateAnnouncement />,
        },
        {
            path: BASE_PATH_TO_REMINDER_IMAGES_LIST,
            element: <ReminderImages />,
        },
        {
            path: BASE_PATH_TO_ADD_REMINDER_IMAGES,
            element: <AddReminderImage />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_REMINDER_IMAGES}/:ID`,
            element: <UpdateReminderImage />,
        }
    ],
};

export default SundriesRoutes;
