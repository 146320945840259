import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { toast } from "react-toastify";
import CustomToolbar from "../../../../components/toolbar";
import { BASE_PATH_TO_ADD_WORKOUT, BASE_PATH_TO_UPDATE_WORKOUT } from "../../../../base_routes_paths/challenge_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { WorkoutServices } from "../../../../services/challenge_services";

const WorkoutIdList = () => {
    const userColumns = [
        { field: "SortID", headerName: "Sort ID", width: 80 },
        { field: "id", headerName: "ID", width: 230 },
        {
            field: "WorkoutImage",
            headerName: "Workout Image",
            width: 120,
            renderCell: (params) => {
                return (
                    <div className={classes.cellWithImg}>
                        <img
                            className={classes.cellImg}
                            src={
                                params.row.WorkoutImage ||
                                "https://www.areno.in/images/logo.png"
                            }
                            alt=""
                        />
                    </div>
                );
            },
        },
        {
            field: "WorkoutName",
            headerName: "WorkoutName",
            width: 150,
        },
        {
            field: "WorkoutID",
            headerName: "WorkoutID",
            width: 120,
        },
        {
            field: "WorkoutUnit",
            headerName: "WorkoutUnit",
            width: 120,
        },
        {
            field: "GyroMinAngle",
            headerName: "GyroMinAngle",
            width: 120,
        },
        {
            field: "GyroMaxAngle",
            headerName: "GyroMaxAngle",
            width: 120,
        },
        {
            field: "Calories",
            headerName: "Calories",
            width: 80,
        },
        {
            field: "DeviceType",
            headerName: "DeviceType",
            width: 120,
        },
        {
            field: "Dated",
            headerName: "Dated",
            width: 230,
            renderCell: (params) => {
                const date = new Date(params.row.Dated);
                const formattedDate = date.toLocaleString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                });

                return <div>{formattedDate}</div>;
            },
        },
        {
            field: "IsRepBased",
            headerName: "IsRepBased",
            width: 120,
        },
        {
            field: "IsActive",
            headerName: "IsActive",
            width: 120,
        },
        {
            field: "IsNew",
            headerName: "IsNew",
            width: 120,
        },
        {
            field: "IsAvailableForClan",
            headerName: "IsAvailableForClan",
            width: 120,
        },
    ];

    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_WorkoutIDsList`;
    const navigate = useNavigate();
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);

    const FetchApi = async () => {
        try {
            let response = await WorkoutServices.getAllWorkout(token, navigate);
            setIsDataFetched(true);

            let result = response.data;

            let input = result.map(({ _id: id, ...result }) => ({
                id,
                ...result,
            }));
            setData(input);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi();
        }
    }, []);

    const handleDelete = (id) => {
        WorkoutServices.deleteWorkout(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <Link
                            href={`${BASE_PATH_TO_UPDATE_WORKOUT}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                        >
                            Update
                        </Link>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.WorkoutID)}
                        >
                            Delete
                        </div>
                    </div >
                );
            },
        },
    ];

    return (
        <div>
            {isDataFetched && (
                <div className={classes.datatable}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Challenges
                        </Typography>
                        <Typography color="text.primary">Workout ID List</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        rows={data}
                        columns={userColumns.concat(actionColumn)}
                        slots={{
                            toolbar: () => (
                                <CustomToolbar
                                    EXPORT_FILENAME={EXPORT_FILENAME}
                                    onClickCreate={() => {
                                        navigate(BASE_PATH_TO_ADD_WORKOUT);
                                    }}
                                    displayCreate={""}
                                    onClickUpdate={() => { }}
                                    displayUpdate={"none"}
                                />
                            ),
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                        }}
                        disableRowSelectionOnClick
                        checkboxSelectionVisibleOnly
                        checkboxSelection
                    />
                </div>
            )}
            {!isDataFetched && <Loader />}
        </div>
    );
};

export default WorkoutIdList;
