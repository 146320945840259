import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { Box, Breadcrumbs, IconButton, Link, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AUTH_CONTEXT from "../../../../store";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_LEADERBOARD } from "../../../../base_routes_paths/data_paths";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import Loader from "../../../../components/Loader/Loader";
import IndianTimeFormat from "../../../../utils";
import { ChallenegeServices } from "../../../../services/challenge_services";

const ChallengeDetails = (props) => {
    const { token } = AUTH_CONTEXT();
    const navigate = useNavigate();
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState({});
    const [RecentTop10Challengers, setRecentTop10Challengers] = useState([]);
    const [SponsoredReward, setSponsoredReward] = useState([]);
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const params = useParams();

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const FetchResponseGetUserDetailsByUserID = async () => {
        try {
            let response = await ChallenegeServices.getChallengeDetails(token, navigate, params.ID);

            setIsDataFetched(true);
            setIsLoaderLoading(false);
            return response.data;
        } catch (e) {
            setIsDataFetched(false);
            console.log(e.message);
            alert(e.message);
        }
    };

    useEffect(() => {
        async function FetchData() {
            try {
                if (!isDataFetched) {
                    setIsLoaderLoading(true);
                    let result = await FetchResponseGetUserDetailsByUserID();
                    result.IsActive = `${result.IsActive}`;
                    result.IsSponsored = `${result.IsSponsored}`;
                    result.IsCumulative = `${result.IsCumulative}`;

                    let input1 = result.RecentTop10Challengers;
                    let temp1 = input1.map(({ _id: id, ...input1 }) => ({
                        id,
                        ...input1,
                    }));
                    setRecentTop10Challengers(temp1);

                    let input2 = result.SponsoredReward;
                    let temp2 = input2.map(({ _id: id, ...input2 }) => ({
                        id,
                        ...input2,
                    }));
                    setSponsoredReward(temp2);

                    setData(result);
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        FetchData();
    }, []);

    return (
        <div>
            {isLoaderLoading === true ? (
                <Loader />
            ) : (
                <div className={classes.crumbsDetails}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Challenege
                        </Typography>
                        <Typography color="text.primary">Challenge Details / {params.ID}</Typography>
                    </Breadcrumbs>
                    <div className={classes.single}>
                        <div className={classes.main}>
                            <div className={classes.cardBackgroundImg}>
                                <img src={data.ChallengeThumbURL} alt="Areno" />
                                <MenuIcon
                                    style={{
                                        position: "absolute",
                                        color: "white",
                                        cursor: "pointer",
                                        margin: 5,
                                    }}
                                    fontSize="large"
                                    onClick={handleOpen}
                                />
                            </div>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                sx={{ mt: 2, width: "500px" }}
                            >
                                <Box
                                    sx={{
                                        p: 2,
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        "&&:hover ": { bgcolor: "#f0f0f0" },
                                    }}
                                >
                                    <Link
                                        href={`${BASE_PATH_TO_LEADERBOARD}/${params.ID}`}
                                        className={classes["button-link"]}
                                    >
                                        Leaderboard
                                        <IconButton>
                                            <LeaderboardIcon className={classes["icon-color"]} />
                                        </IconButton>
                                    </Link>
                                </Box>
                            </Popover>
                            <div className={classes.item}>
                                <div className={classes.details}>
                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Name:</span>
                                        <span className={classes.itemValue}>{data.Name || ""}</span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>CategoryName:</span>
                                        <span className={classes.itemValue}>
                                            {data.CategoryName || ""}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>CategoryID :</span>
                                        <span className={classes.itemValue}>
                                            {data.CategoryID || ""}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>WorkoutID:</span>
                                        <span className={classes.itemValue}>
                                            {data.WorkoutID || ""}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Gender :</span>
                                        <span className={classes.itemValue}>
                                            {data.Gender || ""}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Threshold:</span>
                                        <span className={classes.itemValue}>
                                            {data.Threshold || ""}
                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "20px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                padding: "10px 0px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>Created :</div>
                                            {IndianTimeFormat(data.Dated)}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                padding: "10px 0px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>StartsTimeUtc :</div>
                                            {data.StartsTimeUtc}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                padding: "10px 0px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>JoinByTimeUtc :</div>
                                            {data.JoinByTimeUtc}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                padding: "10px 0px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>EndsInTimeUtc :</div>
                                            {data.EndsInTimeUtc}
                                        </div>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>NoOfAttempts:</span>
                                        <span className={classes.itemValue}>
                                            {data.NoOfAttempts === "" || data.NoOfAttempts === isNaN
                                                ? "N / A"
                                                : data.NoOfAttempts}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>SetPoint:</span>
                                        <span className={classes.itemValue}>
                                            {data.SetPoint === "" || data.SetPoint === isNaN
                                                ? "N / A"
                                                : data.SetPoint}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>
                                            TimeDuration in sec:
                                        </span>
                                        <span className={classes.itemValue}>
                                            {data.TimeDuration === "" || data.TimeDuration === isNaN
                                                ? "N / A"
                                                : data.TimeDuration}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>
                                            DeviceType :
                                        </span>
                                        <span className={classes.itemValue}>
                                            {data.DeviceType === "" || data.DeviceType === isNaN
                                                ? "N / A"
                                                : data.DeviceType}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Participants:</span>
                                        <span className={classes.itemValue}>
                                            {data.Participents === "" || data.Participents === isNaN
                                                ? "N / A"
                                                : data.Participents}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Rewards:</span>
                                        <span className={classes.itemValue}>
                                            {data.Rewards === "" || data.Rewards === isNaN
                                                ? "N / A"
                                                : data.Rewards}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>CorporateID:</span>
                                        <span className={classes.itemValue}>
                                            {data.CorporateID === "" || data.CorporateID === isNaN
                                                ? "N / A"
                                                : data.CorporateID}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>ClanID:</span>
                                        <span className={classes.itemValue}>
                                            {data.ClanID === "" || data.ClanID === isNaN
                                                ? "N / A"
                                                : data.ClanID}
                                        </span>
                                    </div>

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>SmsVideoUrl:</span>
                                        <span className={classes.itemValue}>
                                            {data.SmsVideoUrl === "" || data.SmsVideoUrl === isNaN ? (
                                                "N / A"
                                            ) : (
                                                <a href={data.SmsVideoUrl || ""}>data.CorporateID</a>
                                            )}
                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "20px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>IsActive :</div>
                                            {data.IsActive === "true" && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {data.IsActive !== "true" && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>IsSponsored :</div>
                                            {data.IsSponsored === "true" && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {data.IsSponsored !== "true" && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>IsCumulative :</div>
                                            {data.IsCumulative === "true" && (
                                                <p style={{ color: "green" }}>True</p>
                                            )}
                                            {data.IsCumulative !== "true" && (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "center",
                                                border: ".1px solid #000",
                                                borderRadius: "10px",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div className={classes.itemKey}>SubscriberOnly :</div>
                                            {data.SubscriberOnly === true ? (
                                                <p style={{ color: "green" }}>True</p>
                                            ) : (
                                                <p style={{ color: "red" }}>False</p>
                                            )}
                                        </div>
                                    </div>

                                    {data.IsSponsored === "true" && (
                                        <div className={classes.detailItem}>
                                            <span className={classes.itemKey}>SponsoredReward:</span>
                                            <TableContainer
                                                component={Paper}
                                                className={classes.table}
                                            >
                                                <Table
                                                    sx={{ minWidth: 650, color: "black" }}
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow sx={{ color: "black" }}>
                                                            <TableCell className={classes.tableCell}>
                                                                Ranks
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                Rewards
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {SponsoredReward.map((row) => (
                                                            <TableRow key={row.id}>
                                                                <TableCell className={classes.tableCell}>
                                                                    {row.Ranks}
                                                                </TableCell>
                                                                <TableCell className={classes.tableCell}>
                                                                    {row.Rewards}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    )}

                                    <div className={classes.detailItem}>
                                        <span className={classes.itemKey}>Descriptions:</span>
                                        <span
                                            className={classes.itemValue}
                                            dangerouslySetInnerHTML={{
                                                __html: data.Descriptions || "",
                                            }}
                                        />
                                    </div>

                                    {/* <div className={classes.detailItem}>
                    <span className={classes.itemKey}>Recent Challengers:</span>
                    <TableContainer
                      component={Paper}
                      className={classesTable.table}
                    >
                      <Table
                        sx={{ minWidth: 650, color: "black" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow sx={{ color: "black" }}>
                            <TableCell className={classesTable.tableCell}>
                              UserID
                            </TableCell>
                            <TableCell className={classesTable.tableCell}>
                              Name
                            </TableCell>
                            <TableCell className={classesTable.tableCell}>
                              Location
                            </TableCell>
                            <TableCell className={classesTable.tableCell}>
                              ChallengeAtmpt
                            </TableCell>
                            <TableCell className={classesTable.tableCell}>
                              LastChallengeAtmptTime
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {RecentTop10Challengers.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell className={classesTable.tableCell}>
                                <Link
                                  to={"/GetUserDetails/" + row.id}
                                  style={{}}
                                >
                                  {row.id}
                                </Link>
                              </TableCell>
                              <TableCell className={classesTable.tableCell}>
                                <div className={classesTable.cellWrapper}>
                                  <img
                                    src={
                                      row.ProfileImage ||
                                      "https://areno.in/img/group-1@2x.svg"
                                    }
                                    alt=""
                                    className={classesTable.image}
                                  />
                                  {row.Name}
                                </div>
                              </TableCell>
                              <TableCell className={classesTable.tableCell}>
                                {row.Location}
                              </TableCell>
                              <TableCell className={classesTable.tableCell}>
                                {row.ChallengeAtmpt}
                              </TableCell>
                              <TableCell className={classesTable.tableCell}>
                                {row.LastChallengeAtmptTime}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChallengeDetails;